import styles from "./Status.module.scss";
import CustomButton from "../../../../components/UI/CustomButton/CustomButton";
import plane from "../../../../assets/icons/plane-doc.svg";
import house from "../../../../assets/icons/home-doc.svg";
import {ReactSVG} from "react-svg";
import StatusInfo from "../../../../components/UI/StatusInfo/StatusInfo";
import planeGrey from "../../../../assets/icons/plane-grey.png";
import arrowOrange from "../../../../assets/icons/arrowOrange.svg";
import fileComplete from "../../../../assets/icons/fileComplete.svg";
import {BASE_URL, BASE_URL_FRONT} from "../../../../api/constants";
import React, {useEffect} from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import Tooltip from "../../../../components/UI/Tooltip/Tooltip";
import warning from "../../../../assets/icons/warning-gray.svg";
import {pluralizeDay} from "../../../../utils/tariffService";

const Status = ({
                    orderNo,
                    status,
                    date,
                    passengersQuantity,
                    sum,
                    children,
                    fromTown,
                    toTown,
                    startDate,
                    endDate,
                    clickHandler,
                    orderId,
                    hotelBookingFile,
                    flightBookingFile,
                    flightToPnr,
                    flightFromPnr,
                    flightToBookingExpiresAt,
                    flightFromBookingExpiresAt,
                    flightToAirUrl,
                    flightFromAirUrl,
                    tariff,
                    orderRenewal,
                    orderRenewalDay
                }) => {
    dayjs.extend(customParseFormat);
    dayjs.extend(utc);

    const flightLink = () => {
        window.location.replace(
            `${BASE_URL}orders/download?id=` + orderId + "&booking_type=flight"
        );
    };

    const hotelLink = () => {
        window.location.replace(
            `${BASE_URL}orders/download?id=` + orderId + "&booking_type=hotel"
        );
    };

    const paymentClick = () => {
        window.location.replace(`${BASE_URL_FRONT}bookingPayment/` + orderId);
    };

    const redirectToPaymentTinkoff = (values, errors) => {
        window.location.replace(
            `${BASE_URL}payments/tinkoff/redirect?id=` + orderId
        );
    };

    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    let flightToAirUrlArr = flightToAirUrl ? JSON.parse(flightToAirUrl) : [];
    let flightFromAirUrlArr = flightFromAirUrl
        ? JSON.parse(flightFromAirUrl)
        : [];

    let orderRenewalDate = dayjs(orderRenewalDay)
        .utc(true)
        .format("DD.MM.YYYY HH:mm")

    return (
        <div className={styles.status}>
            <div className={styles.hotel__info}>
                <h4
                    className={styles.hotel__info_title}
                >{`${fromTown} - ${toTown}`}</h4>
                <p className={styles.hotel__info_text}>
                    {endDate ? `${startDate} - ${endDate}` : startDate}
                </p>
                <img src={planeGrey} alt="planeImg"/>
            </div>
            <div className={styles.about}>
                <div className={styles.about__content}>
                    <div className={styles.about__content_item}>
                        <h4 className={styles.about__content_title}>
                            Статус бронирования:
                        </h4>
                        <StatusInfo statusType={status}/>
                    </div>
                    <div className={styles.about__content_item}>
                        <h4 className={styles.about__content_title}>
                            Дата бронирования:
                        </h4>
                        <p className={styles.about__content_text}>
                            {dayjs(date)
                                .utc(true)
                                .local()
                                .format("DD.MM.YYYY HH:mm")}
                        </p>
                    </div>
                    <div className={styles.about__content_item}>
                        <h4 className={styles.about__content_title}>
                            Пассажиры:
                        </h4>
                        <p className={styles.about__content_text}>
                            {passengersQuantity}
                        </p>
                    </div>
                    <div className={styles.about__content_item}>
                        <h4 className={styles.about__content_title}>Сумма:</h4>
                        <p className={styles.about__content_text}>{sum}</p>
                        <div className={styles.about__content_info}>
                            {children}
                        </div>
                    </div>
                </div>
                <div className={styles.about__content}>
                    <div className={styles.about__content_order_no}>
                        <h4 className={styles.about__content_title}>
                            Номер заказа:
                        </h4>
                        {orderNo}
                    </div>
                    <div className={styles.about__content_order_no}>
                        <h4 className={styles.about__content_title}>Код:</h4>
                        <p>{flightToPnr}</p>
                        <p>{flightFromPnr}</p>
                    </div>
                    <div className={styles.about__content_order_no}>
                        {flightToAirUrlArr?.code ? (
                            <>
                                <h4 className={styles.about__content_title}>
                                    Проверка:
                                </h4>
                                <p>
                                    <a
                                        href={flightToAirUrlArr?.site}
                                        target="_blank"
                                    >
                                        {flightToAirUrlArr?.name ??
                                            flightToAirUrlArr?.code}
                                    </a>
                                </p>
                                <p>
                                    <a
                                        href={flightFromAirUrlArr?.site}
                                        target="_blank"
                                    >
                                        {flightFromAirUrlArr?.name ??
                                            flightFromAirUrlArr?.code}
                                    </a>
                                </p>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {hotelBookingFile || flightBookingFile ? (
                <div className={styles.documents}>
                    <p className={styles.documents__subtitle}>
                        Скачать документы:
                    </p>
                    <div className={styles.documents__buttons}>

                        {flightBookingFile ? (
                            <CustomButton
                                classes={styles.documents__btn}
                                linedBlack={true}
                                onClick={flightLink}
                            >
                                <ReactSVG src={plane}/>
                                Маршрутная квитанция
                            </CustomButton>
                        ) : null}
                        {hotelBookingFile ? (
                            <CustomButton
                                classes={styles.documents__btn}
                                linedBlack={true}
                                onClick={hotelLink}
                            >
                                <ReactSVG src={house}/> Бронь отеля
                            </CustomButton>
                        ) : null}
                        {orderRenewal === true && flightToBookingExpiresAt != null ? (
                            <>

                                <CustomButton
                                    classes={styles.payment__btn}
                                    onClick={paymentClick}
                                    primary
                                >
                                    Продлить до {orderRenewalDate} <Tooltip
                                    content={"После оплаты заказа срок брони будет автоматически продлен на " + (Math.floor(tariff.term_start / 24)) +  (
                                        tariff.term_start !== tariff.term_end ? (" - " + Math.floor(tariff.term_end / 24)) : ' '
                                    ) + " " + pluralizeDay(Math.floor(tariff.term_end / 24))}
                                    children={<ReactSVG src={warning}/>}
                                />
                                </CustomButton>
                            </>
                        ) : null
                        }
                    </div>
                </div>
            ) : status === "Оформляется" ? (
                <div className={styles.status__issued}>
                    <p className={styles.documents__subtitle}>
                        Скачать документы:
                    </p>
                    <div className={styles.status__issued_doc}>
                        <ReactSVG src={fileComplete}/>
                        <p className={styles.status__issued_text}>
                            Документы будут доступны в ближайшее время
                        </p>
                    </div>
                </div>
            ) : null}
            {status === "Не оплачено" ? (
                <div className={styles.payment}>
                    <div className={styles.payment__buttons}>
                        <CustomButton
                            classes={styles.payment__btn}
                            onClick={paymentClick}
                            primary
                        >
                            К оплате
                        </CustomButton>
                    </div>
                </div>
            ) : null}
            <div className={styles.status__btn}>
                <button onClick={clickHandler}>Подробнее</button>
                <ReactSVG src={arrowOrange}/>
            </div>
        </div>
    );
};

export default Status;
